import React from "react";
import PopularChair from "../popularItem/PopularChair";

function Chairs() {
  return (
    <div>
      <div className="chair">
        <div className="box-heading-container">
          <h1 className="center text-white">Get all kinds of chairs</h1>
          <p className="text-white">In best price</p>
        </div>
      </div>
      <div className="container">
        <h1 className="display-4">Chairs</h1>
        <PopularChair />
      </div>
    </div>
  );
}

export default Chairs;
