import React from "react";
import { useState } from "react";
import { db } from "../../config/Config";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

function AddShoeRack() {
  const [name, setName] = useState();
  const [size, setSize] = useState();
  const [model, setModel] = useState();
  const [color, setColor] = useState();
  const [material, setMaterial] = useState();
  const [drawer, setDrawer] = useState();
  const [shelve, setShelve] = useState();
  const [price, setPrice] = useState();
  const [urlone, setUrlone] = useState();
  const [urltwo, setUrltwo] = useState();
  const [urlthree, setUrlthree] = useState();
  const [feature, setFeature] = useState();
  const [caution, setCaution] = useState();

  const addName = (e) => {
    setName(e.target.value);
  };

  const addColor = (e) => {
    setColor(e.target.value);
  };
  const addModel = (e) => {
    setModel(e.target.value);
  };
  const addSize = (e) => {
    setSize(e.target.value);
  };
  const addMaterial = (e) => {
    setMaterial(e.target.value);
  };
  const addDrawer = (e) => {
    setDrawer(e.target.value);
  };
  const addShelve = (e) => {
    setShelve(e.target.value);
  };
  const addPrice = (e) => {
    setPrice(e.target.value);
  };
  const addUrlone = (e) => {
    setUrlone(e.target.value);
  };
  const addUrltwo = (e) => {
    setUrltwo(e.target.value);
  };
  const addUrlthree = (e) => {
    setUrlthree(e.target.value);
  };
  const addfeature = (e) => {
    setFeature(e.target.value);
  };
  const addCaution = (e) => {
    setCaution(e.target.value);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    db.collection("shoerack")
      .add({
        name,
        color,
        model,
        size,
        drawer,
        material,
        shelve,
        price,
        urlone,
        urltwo,
        urlthree,
        feature,
        caution,
        createdAt: new Date(),
      })
      .then(() => {
        setName("");
        setColor("");
        setModel("");
        setSize("");
        setDrawer("");
        setMaterial("");
        setShelve("");
        setPrice("");
        setUrlone("");
        setUrltwo("");
        setUrlthree("");
        setFeature("");
        setCaution("");
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <h1 className="text-success">Success!</h1>
                <p>Add have Added new Item</p>
                <button className="btn btn-success" onClick={onClose}>
                  Ok
                </button>
              </div>
            );
          },
        });
      });
  };
  return (
    <div className="container">
      <h1>Add Shoe Rack</h1>
      <div className="form-group">
        <form onSubmit={submitHandler}>
          <div className="form-row">
            <div className="col">
              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="Name"
                value={name}
                onChange={addName}
              />
              <input
                required
                className="form-control my-2"
                type="Text"
                placeholder="COLOR"
                value={color}
                onChange={addColor}
              />
              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="MODLE NO."
                value={model}
                onChange={addModel}
              />
              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="SIZE"
                value={size}
                onChange={addSize}
              />

              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="No. of Drawer"
                value={drawer}
                onChange={addDrawer}
              />
              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="MATERIAL"
                value={material}
                onChange={addMaterial}
              />
              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="No. of Shelves"
                value={shelve}
                onChange={addShelve}
              />
              <input
                required
                className="form-control my-2"
                type="number"
                placeholder="PRICE"
                value={price}
                onChange={addPrice}
              />
              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="Enter 1st image url"
                value={urlone}
                onChange={addUrlone}
              />
              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="Enter 2nd image url"
                value={urltwo}
                onChange={addUrltwo}
              />
              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="Enter 3rd image url"
                value={urlthree}
                onChange={addUrlthree}
              />
            </div>
            <div className="col">
              <p>FEATURE</p>
              <textarea
                required
                className="form-control"
                rows="5"
                name="feature"
                value={feature}
                onChange={addfeature}
              ></textarea>
              <p>CARE INSTRUCTION</p>
              <textarea
                required
                className="form-control"
                rows="5"
                name="caution"
                value={caution}
                onChange={addCaution}
              ></textarea>
              <button className="btn btn-info mt-4 mb-4">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddShoeRack;
