import React, { useState } from "react";
import { db } from "../../config/Config";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import emailjs from "emailjs-com";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthProvider";
import { Redirect } from "react-router-dom";

const BulkOrder = () => {
  const [authstate, setAuthState] = useContext(AuthContext);
  const [user, setUser] = useState([]);
  const [username, setUserName] = useState([]);
  const [phone, setPhone] = useState([]);
  const [email, setMail] = useState([]);
  const [address, setAddress] = useState([]);
  const [message, setMessage] = useState([]);

  const addUserName = (e) => {
    setUserName(e.target.value);
  };
  const addPhone = (e) => {
    setPhone(e.target.value);
  };
  const addMail = (e) => {
    setMail(e.target.value);
  };
  const addAddress = (e) => {
    setAddress(e.target.value);
  };
  const addMessage = (e) => {
    setMessage(e.target.value);
  };

  const templateParams = {
    username,
    phone,
  };

  const submitHandler = (e) => {
    e.preventDefault();
    db.collection("bookings")
      .add({
        username,
        phone,
        address,
        email,
        message,
        createdAt: new Date(),
      })
      .then(() => {
        emailjs
          .send(
            "service_7imv8b8",
            "template_ln5duyg",
            templateParams,
            "user_i09OH1iltYunUUsqN1V8R"
          )
          .then(
            (result) => {
              console.log(result.text);
            },
            (error) => {
              console.log(error.text);
            }
          );
        setUserName("");
        setPhone("");
        setMail("");
        setAddress("");
        setMessage("");
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <h1 className="text-success">Your Order Placed</h1>
                <p>Contact us for any query</p>
                <button className="btn btn-success" onClick={onClose}>
                  Ok
                </button>
              </div>
            );
          },
        });
      });
  };

  if (!authstate.user) {
    return <Redirect to="/Login" />;
  } else {
    return (
      <div>
        <div className="container">
          <form onSubmit={submitHandler}>
            <div className="row">
              <div className="col-md-6 mt-5">
                <h1 className="mt-3 text-warning">Please contact for more</h1>
                <p>Give us a call on +91 9031303351 and +91821065574</p>
              </div>
              <div className="col-md-6 mt-5">
                <input
                  required
                  type="text"
                  placeholder="Name:"
                  className="form-control mb-2 mt-2"
                  value={username}
                  onChange={addUserName}
                />
                <input
                  required
                  type="text"
                  placeholder="Address:"
                  className="form-control mb-2 mt-2"
                  value={address}
                  onChange={addAddress}
                />
                <input
                  required
                  type="number"
                  placeholder="Phone No.:"
                  className="form-control mb-2 mt-2"
                  value={phone}
                  onChange={addPhone}
                />
                <input
                  required
                  type="email"
                  placeholder="E-mail:"
                  className="form-control mb-2 mt-2"
                  value={email}
                  onChange={addMail}
                />
                <textarea
                  required
                  cols="30"
                  rows="10"
                  className="form-control"
                  value={message}
                  onChange={addMessage}
                  placeholder="Send Message"
                ></textarea>
                <button type="submit" className="btn btn-success mt-4 mb-4">
                  Buy Now
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
};

export default BulkOrder;
