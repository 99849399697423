import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../config/Config";

function PopularSofa() {
  const [sofas, setSofas] = useState();

  useEffect(() => {
    db.collection("sofa")
      .get()
      .then((Snapshot) => {
        const newsofas = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(newsofas);
        setSofas(newsofas);
      })
      .catch();
  }, []);

  return (
    <div className="row">
      {sofas &&
        sofas.map((sofa) => {
          return (
            <div className="col-md-4 mb-4 mt-4" key={sofa.id}>
              <div className="card">
                <img
                  src={sofa.urlone}
                  alt="..."
                  className="card-img-top img-fluid "
                />
                <div className="card-body">
                  <h5>{sofa.name}</h5>
                  <p>&#x20B9; {sofa.price}</p>
                  <Link
                    to={`/sofaDetail/${sofa.id}`}
                    className="btn btn-info"
                    key={sofa.id}
                  >
                    order
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default PopularSofa;
