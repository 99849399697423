import React from "react";
import Cart from "./Cart";
import CartList from "./CartList";
import CartProvider from "./CartProvider";

function CartHolder() {
  return (
    <div>
      <CartList />
    </div>
  );
}

export default CartHolder;
