import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { createContext } from "react";
import { auth } from "../../config/Config";

export const AuthContext = createContext();
function AuthProvider(props) {
  const [authstate, setAuthState] = useState([]);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setAuthState({ user, email: user.email });
        // ...
        console.log(user);
      } else {
        setAuthState({ user: null });
        console.log("singed out");
      }
    });
  }, []);
  return (
    <AuthContext.Provider value={[authstate, setAuthState]}>
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
