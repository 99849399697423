import React from "react";

function Footer() {
  return (
    <div className="jumbotron jumbotron-fluid">
      <div className="container">
        <h1 className="display-4">Book My Furniture</h1>
        <h5>
          Byepass Road, Panderpala, B.Polytechnic, Dhanbad-828130(Jharkhand)
        </h5>
        <p className="lead no-printme">
          Copy Right &copy; Powered by
          <a href="https://glasscode-fcea6.web.app/">CodeOverWrite</a>
        </p>
      </div>
    </div>
  );
}

export default Footer;
