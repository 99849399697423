import React from "react";
import PopularBookShelves from "../popularItem/PopularBookShelves";
import PopularTable from "../popularItem/PopularTable";

function Office() {
  return (
    <div>
      <div className="office">
        <div className="box-heading-container">
          <h1 className="center text-white">Study-Room/Office</h1>
          <p className="text-white">
            Get all kind of study tables, office table and Book Shelves in best
            price.
          </p>
        </div>
      </div>
      <div className="container">
        <h1 className="display-4">Study and Office Table</h1>
        <PopularTable />
        <h1 className="display-4">Book Shelves</h1>
        <PopularBookShelves />
      </div>
    </div>
  );
}

export default Office;
