import React from "react";
import { useState } from "react";
import { db } from "../../config/Config";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

function AddChair() {
  const [name, setName] = useState();
  const [color, setColor] = useState();
  const [model, setModel] = useState();
  const [size, setSize] = useState();
  const [backrest, setBackrest] = useState();
  const [legmaterial, setLegmaterial] = useState();
  const [upholsterymaterial, setUpholsterymaterial] = useState();
  const [cushion, setCushion] = useState();
  const [armrest, setArmrest] = useState();
  const [swivel, setSwivel] = useState();
  const [heightadjustable, setHeightadjustable] = useState();
  const [reassembly, setReassembly] = useState();
  const [wheel, setWheel] = useState();
  const [materialsubtype, setMaterialsubtype] = useState();
  const [price, setPrice] = useState();
  const [urlone, setUrlone] = useState();
  const [urltwo, setUrltwo] = useState();
  const [urlthree, setUrlthree] = useState();
  const [feature, setFeature] = useState();
  const [caution, setCaution] = useState();

  const addName = (e) => {
    setName(e.target.value);
  };

  const addColor = (e) => {
    setColor(e.target.value);
  };
  const addModel = (e) => {
    setModel(e.target.value);
  };
  const addSize = (e) => {
    setSize(e.target.value);
  };

  const addBackrest = (e) => {
    setBackrest(e.target.value);
  };
  const addLegmaterial = (e) => {
    setLegmaterial(e.target.value);
  };
  const addUpholstery = (e) => {
    setUpholsterymaterial(e.target.value);
  };
  const addCushion = (e) => {
    setCushion(e.target.value);
  };
  const addArmrest = (e) => {
    setArmrest(e.target.value);
  };
  const addSwivel = (e) => {
    setSwivel(e.target.value);
  };
  const addheightadjustable = (e) => {
    setHeightadjustable(e.target.value);
  };
  const addReassembly = (e) => {
    setReassembly(e.target.value);
  };
  const addWheel = (e) => {
    setWheel(e.target.value);
  };
  const addmaterialsubtype = (e) => {
    setMaterialsubtype(e.target.value);
  };
  const addPrice = (e) => {
    setPrice(e.target.value);
  };
  const addUrlone = (e) => {
    setUrlone(e.target.value);
  };
  const addUrltwo = (e) => {
    setUrltwo(e.target.value);
  };
  const addUrlthree = (e) => {
    setUrlthree(e.target.value);
  };
  const addFeature = (e) => {
    setFeature(e.target.value);
  };
  const addCaution = (e) => {
    setCaution(e.target.value);
  };
  const submitHandler = (e) => {
    e.preventDefault();
    db.collection("chairs")
      .add({
        name,
        color,
        model,
        size,
        backrest,
        legmaterial,
        upholsterymaterial,
        cushion,
        armrest,
        swivel,
        heightadjustable,
        reassembly,
        wheel,
        materialsubtype,
        price,
        urlone,
        urltwo,
        urlthree,
        feature,
        caution,
        createdAt: new Date(),
      })
      .then(() => {
        setName("");
        setColor("");
        setModel("");
        setSize("");
        setBackrest("");
        setLegmaterial("");
        setUpholsterymaterial("");
        setCushion("");
        setArmrest("");
        setSwivel("");
        setHeightadjustable("");
        setReassembly("");
        setWheel("");
        setMaterialsubtype("");
        setPrice("");
        setUrlone("");
        setUrltwo("");
        setUrlthree("");
        setFeature("");
        setCaution("");
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <h1 className="text-success">Success!</h1>
                <p>Add have Added new Item</p>
                <button className="btn btn-success" onClick={onClose}>
                  Ok
                </button>
              </div>
            );
          },
        });
      });
  };
  return (
    <div className="container">
      <h1>Add Chairs' detail</h1>
      <div className="form-group">
        <form onSubmit={submitHandler}>
          <div className="form-row">
            <div className="col">
              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="Name"
                onChange={addName}
                value={name}
              />
              <input
                required
                className="form-control my-2"
                type="Text"
                placeholder="COLOR"
                onChange={addColor}
                value={color}
              />
              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="MODLE NO."
                onChange={addModel}
                value={model}
              />
              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="SIZE"
                onChange={addSize}
                value={size}
              />
              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="BACKREST"
                onChange={addBackrest}
                value={backrest}
              />
              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="LEG MATERIAL"
                onChange={addLegmaterial}
                value={legmaterial}
              />
              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="UPHOLSTERY MATERIAL"
                onChange={addUpholstery}
                value={upholsterymaterial}
              />
              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="CUSHION TYPE"
                onChange={addCushion}
                value={cushion}
              />
              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="ARMREST"
                onChange={addArmrest}
                value={armrest}
              />
              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="HAS SWIVEL"
                onChange={addSwivel}
                value={swivel}
              />
              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="HEIGHT ADJUSTABLE"
                onChange={addheightadjustable}
                value={heightadjustable}
              />
              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="RE-ASSEMBLY POSSIBLE "
                onChange={addReassembly}
                value={reassembly}
              />
              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="WHEELS"
                onChange={addWheel}
                value={wheel}
              />
              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="MATERIAL SUB TYPE"
                onChange={addmaterialsubtype}
                value={materialsubtype}
              />
              <input
                required
                className="form-control my-2"
                type="number"
                placeholder="PRICE"
                onChange={addPrice}
                value={price}
              />
              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="enter  1st image url"
                onChange={addUrlone}
                value={urlone}
              />
              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="enter  2nd image url"
                onChange={addUrltwo}
                value={urltwo}
              />
              <input
                required
                className="form-control my-2"
                type="text"
                placeholder="enter 3rd image url"
                onChange={addUrlthree}
                value={urlthree}
              />
            </div>
            <div className="col">
              <p>FEATURE</p>
              <textarea
                required
                className="form-control"
                rows="5"
                name="feature"
                onChange={addFeature}
                value={feature}
              ></textarea>
              <p>CARE INSTRUCTION</p>
              <textarea
                required
                className="form-control"
                rows="5"
                name="instruction"
                onChange={addCaution}
                value={caution}
              ></textarea>
              <button className="btn btn-info mt-4 mb-4">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddChair;
