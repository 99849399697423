import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { db } from "../../config/Config";
import { AuthContext } from "./AuthProvider";
import Cart from "./Cart";

function CartList() {
  const [basket, setBasket] = useState([]);
  const [authstate, setAuthState] = useContext(AuthContext);

  const { email } = authstate;
  useEffect(() => {
    db.collection("cart")
      .where("email", "==", `${email}`)
      .onSnapshot((snapshot) => {
        const newBaskets = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBasket(newBaskets);
      });
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        {basket &&
          basket.map((item) => (
            <Cart
              key={item.id}
              id={item.id}
              itemname={item.itemname}
              username={item.username}
              date={item.createdAt}
              phone={item.phone}
              email={item.email}
              color={item.color}
              model={item.model}
              price={item.price}
              id={item.id}
              email={item.email}
            />
          ))}
      </div>
    </div>
  );
}

export default CartList;
