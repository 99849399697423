import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyB-eRAbb2qM-ErFFzwGWIx3TOd8xnZnbcs",
  authDomain: "clone-3665b.firebaseapp.com",
  databaseURL: "https://clone-3665b.firebaseio.com",
  projectId: "clone-3665b",
  storageBucket: "clone-3665b.appspot.com",
  messagingSenderId: "1016457064707",
  appId: "1:1016457064707:web:e78a583e4b90b71e9eb936",
  measurementId: "G-DP1TS18V99",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const db = firebase.firestore();

export default firebase;
