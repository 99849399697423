import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import Home from "./Components/Home";
import BedRoom from "./Components/category/BedRoom";
import LivingRoom from "./Components/category/LivingRoom";
import Office from "./Components/category/Office";
import DiningRoom from "./Components/category/DiningRoom";
import Wardrobes from "./Components/category/Wardrobes";
import Chairs from "./Components/category/Chairs";
import Admin from "./Components/Admin.js/Admin";
import ProductDetail from "./Components/Product/ProductDetail";
import DressingDetail from "./Components/Product/DressingDetail";
import StorageDetail from "./Components/Product/StorageDetail";
import CornorDetail from "./Components/Product/CornorDetail";
import MattressDetail from "./Components/Product/MattressDetail";
import SofaDetail from "./Components/Product/SofaDetail";
import ShoeDetail from "./Components/Product/ShoeDetail";
import TeaTableDetail from "./Components/Product/TeaTableDetail";
import TvUnitDetail from "./Components/Product/TvUnitDetail";
import TableDetail from "./Components/Product/TableDetail";
import BookDetail from "./Components/Product/BookDetail";
import DiningDetail from "./Components/Product/DiningDetail";
import WardrobeDetail from "./Components/Product/WardrobeDetail";
import ChairDetail from "./Components/Product/ChairDetail";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import BulkOrder from "./Components/Product/BulkOrder";
import Login from "./Components/Auth/Login";
import AuthProvider from "./Components/contexts/AuthProvider";
import CartHolder from "./Components/contexts/CartHolder";
import SingUp from "./Components/Auth/SingUp";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Switch>
          <AuthProvider>
            <Navbar />
            <Route exact path="/" component={Home} />
            <Route path="/bedroom" component={BedRoom} />
            <Route path="/livingroom" component={LivingRoom} />
            <Route path="/office" component={Office} />
            <Route path="/dining" component={DiningRoom} />
            <Route path="/wardrobe" component={Wardrobes} />
            <Route path="/chair" component={Chairs} />
            <Route path="/Login" component={Login} />
            <Route path="/signup" component={SingUp} />
            <Route path="/cart" component={CartHolder} />

            <Route path="/Bulk" component={BulkOrder} />
            <Route path="/productDetail/:id" component={ProductDetail} />
            <Route path="/dressingDetail/:id" component={DressingDetail} />
            <Route path="/storageDetail/:id" component={StorageDetail} />
            <Route path="/cornorDetail/:id" component={CornorDetail} />
            <Route path="/mattressDetail/:id" component={MattressDetail} />
            <Route path="/sofaDetail/:id" component={SofaDetail} />
            <Route path="/shoeRackDetail/:id" component={ShoeDetail} />
            <Route path="/teatableDetail/:id" component={TeaTableDetail} />
            <Route path="/tvunitDetail/:id" component={TvUnitDetail} />
            <Route path="/tableDetail/:id" component={TableDetail} />
            <Route path="/bookDetail/:id" component={BookDetail} />
            <Route path="/diningDetail/:id" component={DiningDetail} />
            <Route path="/wardrobeDetail/:id" component={WardrobeDetail} />
            <Route path="/chairDetail/:id" component={ChairDetail} />
          </AuthProvider>
        </Switch>
        <Route path="/admin" component={Admin} />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
