import React from "react";
import { db } from "../../config/Config";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import emailjs from "emailjs-com";
import { useState } from "react";

function Cart({ itemname, color, model, price, id, username, phone, email }) {
  const [address, setAddress] = useState([]);

  const removeItem = (id) => {
    db.collection("cart").doc(id).delete();
  };

  const addAddress = (e) => {
    setAddress(e.target.value);
  };

  const templateParams = {
    username,
    phone,
  };
  const buyNow = (id) => {
    db.collection("bookings")
      .add({
        username,
        address,
        phone,
        email,
        color,
        itemname,
        model,
        price,
        createdAt: new Date(),
      })
      .then(() => {
        emailjs
          .send(
            "service_7imv8b8",
            "template_ln5duyg",
            templateParams,
            "user_i09OH1iltYunUUsqN1V8R"
          )
          .then(
            (result) => {
              console.log(result.text);
            },
            (error) => {
              console.log(error.text);
            }
          );
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <h1 className="text-success">Booking Successful!</h1>
                <p>Contact us for any query</p>
                <button className="btn btn-success" onClick={onClose}>
                  Ok
                </button>
              </div>
            );
          },
        });
        db.collection("cart").doc(id).delete();
      });
  };
  return (
    <div className="col-md-4 mt-4 mb-4">
      <div className="card">
        <div className="card-body">
          <input
            className="form-control"
            type="text"
            placeholder="Enter Address"
            value={address}
            onChange={addAddress}
          />
          <h5 className="card-title">{itemname}</h5>
          <ul className="bill">
            <li>Color: {color}</li>
            <li>Model: {model}</li>
            <li>Price: {price}</li>
          </ul>
          <button onClick={() => removeItem(id)} className="btn btn-primary">
            remove
          </button>
          <button onClick={() => buyNow(id)} className="btn btn-warning mx-1">
            Buy Now
          </button>
        </div>
      </div>
    </div>
  );
}

export default Cart;
