import React from "react";
import NewlyAdded from "./NewlyAdded";
import PopularItemList from "./PopularItemList";
import offer from "../images/offer.jpg";

function Home() {
  return (
    <div>
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-ride="carousel"
      >
        <ol className="carousel-indicators">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="0"
            className="active"
          ></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src="https://furnituremartworldwide.com/assets/img/slide2.jpg"
              className="d-block "
              alt="..."
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          <div className="carousel-item">
            <img
              src="https://a.mktgcdn.com/p/Kqf8KyWUmmFCDve6j_fYXISC0_-cqZ0-0-dnTdC53yE/1440x600.jpg"
              className="d-block "
              alt="..."
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          <div className="carousel-item">
            <img
              src="https://woodcraftinteriors.in/wp-content/uploads/2017/04/beds1.jpg"
              className="d-block "
              alt="..."
              style={{ width: "100%", height: "auto" }}
            />
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>

      <PopularItemList />
      <div className="container-fluid">
        <img src={offer} alt="banner" className="res-img" />
      </div>
      <NewlyAdded />
    </div>
  );
}

export default Home;
