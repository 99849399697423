import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { db } from "../../config/Config";

function PopularDining() {
  const [dinings, setDinings] = useState();

  useEffect(() => {
    db.collection("diningtable")
      .get()
      .then((Snapshot) => {
        const newdinings = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(newdinings);
        setDinings(newdinings);
      })
      .catch();
  }, []);

  return (
    <div className="row">
      {dinings &&
        dinings.map((dining) => {
          return (
            <div className="col-md-4 mb-4 mt-4" key={dining.id}>
              <div className="card">
                <img
                  src={dining.urlone}
                  alt="..."
                  className="card-img-top img-fluid "
                />
                <div className="card-body">
                  <h5>{dining.name}</h5>
                  <p>&#x20B9; {dining.price}</p>
                  <Link
                    to={`/diningDetail/${dining.id}`}
                    className="btn btn-info"
                    key={dining.id}
                  >
                    order
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default PopularDining;
