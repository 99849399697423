import React, { useState } from "react";
import "./Login.css";

import { auth } from "../../config/Config";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

function SingUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [photoURL, setPhotoURL] = useState("");

  const register = (e) => {
    e.preventDefault();

    auth
      .createUserWithEmailAndPassword(email, password)
      .then((auth) => {
        auth.user.updateProfile({
          displayName,
          photoURL,
        });

        console.log(auth);
        setEmail("");
        setPassword("");
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <h1 className="text-success">Success!</h1>
                <p>
                  You have successfully created your account please login by
                  using Your Current account
                </p>
                <button className="btn btn-success" onClick={onClose}>
                  Ok
                </button>
              </div>
            );
          },
        });
      })
      .catch((error) => alert(error.message));

    // do some fancy firebase register shittt.....
  };

  return (
    <div className="login">
      <div className="login-container mt-4">
        <h1 className="mt-4">Sign-Up</h1>
        <form>
          <h5>E-mail</h5>
          <input
            required
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <h5>Password</h5>
          <input
            required
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <h5>Name</h5>
          <input
            required
            type="text"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
          />
          <h5>Phone No.</h5>
          <input
            required
            type="number"
            value={photoURL}
            onChange={(e) => setPhotoURL(e.target.value)}
          />
        </form>
        <p>
          By signing-in you agree to Bookmyfurniture's condition od use & Sale.
          Please see our privacy Notice, our Cookies Notice and our
          Internet-Based Ads Notice.
        </p>
        <button onClick={register} className="login-register-button">
          Create Your Account
        </button>
      </div>
    </div>
  );
}

export default SingUp;
