import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../config/Config";

function PopularShoerack() {
  const [shoes, setShoes] = useState([]);

  useEffect(() => {
    db.collection("shoerack")
      .get()
      .then((Snapshot) => {
        const newshoerack = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(newshoerack);
        setShoes(newshoerack);
      })
      .catch();
  }, []);

  return (
    <div className="row">
      {shoes &&
        shoes.map((shoe) => {
          return (
            <div className="col-md-4 mb-4 mt-4" key={shoe.id}>
              <div className="card">
                <img
                  src={shoe.urlone}
                  alt="..."
                  className="card-img-top img-fluid"
                />
                <div className="card-body">
                  <h5>{shoe.name}</h5>
                  <p>&#x20B9; {shoe.price}</p>
                  <Link
                    to={`/shoeRackDetail/${shoe.id}`}
                    className="btn btn-info"
                    key={shoe.id}
                  >
                    order
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default PopularShoerack;
