import React from "react";
import { Route } from "react-router-dom";
import AddBed from "../forms/AddBed";
import AddBookShelves from "../forms/AddBookShelves";
import AddChair from "../forms/AddChair";
import AddCoffeeTeaTable from "../forms/AddCoffeeTeaTable";
import AddCorner from "../forms/AddCorner";
import AddDiningTable from "../forms/AddDiningTable";
import AddDressingtable from "../forms/AddDressingtable";
import AddMattresses from "../forms/AddMattresses";
import AddShoeRack from "../forms/AddShoeRack";
import AddSofa from "../forms/AddSofa";
import AddStorage from "../forms/AddStorage";
import AddTable from "../forms/AddTable";
import AddTvUnit from "../forms/AddTvUnit";
import AddWardrobe from "../forms/AddWardrobe";
import AdminNavbar from "./AdminNavbar";
import Booking from "./Booking";
import BookingDetail from "./BookingDetail";

function Admin() {
  return (
    <div>
      <AdminNavbar />
      <h1 className="no-printme">Admin</h1>

      <Route exact path="/admin/" component={AddTable} />
      <Route path="/admin/addbed" component={AddBed} />
      <Route path="/admin/addchair" component={AddChair} />
      <Route path="/admin/adddressing" component={AddDressingtable} />
      <Route path="/admin/addwardrobe" component={AddWardrobe} />
      <Route path="/admin/addstorage" component={AddStorage} />
      <Route path="/admin/addmatt" component={AddMattresses} />
      <Route path="/admin/addcoffee" component={AddCoffeeTeaTable} />
      <Route path="/admin/addbook" component={AddBookShelves} />
      <Route path="/admin/adddining" component={AddDiningTable} />
      <Route path="/admin/addsofa" component={AddSofa} />
      <Route path="/admin/addshoe" component={AddShoeRack} />
      <Route path="/admin/addtv" component={AddTvUnit} />
      <Route path="/admin/addcorner" component={AddCorner} />
      <Route path="/admin/Booking" component={Booking} />
      <Route path="/admin/bookingDetail/:id" component={BookingDetail} />
    </div>
  );
}

export default Admin;
