import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../config/Config";

function PopularTeaTable() {
  const [teatables, setTeatables] = useState([]);

  useEffect(() => {
    db.collection("teatable")
      .get()
      .then((Snapshot) => {
        const newteatable = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setTeatables(newteatable);
      })
      .catch();
  }, []);

  return (
    <div className="row">
      {teatables &&
        teatables.map((table) => {
          return (
            <div className="col-md-4 mb-4 mt-4" key={table.id}>
              <div className="card" >
                <img
                  
                  src={table.urlone}
                  alt="..."
                  className="card-img-top img-fluid "
                />
                <div className="card-body">
                  <h5>{table.name}</h5>
                  <p>&#x20B9; {table.price}</p>
                  <Link
                    to={`/teatableDetail/${table.id}`}
                    className="btn btn-info"
                    key={table.id}
                  >
                    order
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default PopularTeaTable;
