import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../config/Config";

const NewlyAdded = () => {
  const [tables, setTables] = useState([]);
  const [beds, setBeds] = useState([]);
  const [chairs, setChairs] = useState([]);
  const [dinings, setDinings] = useState([]);
  const [dressings, setDressings] = useState([]);
  const [sofas, setSofas] = useState([]);
  const [storages, setStorages] = useState([]);
  const [wardrobes, setWardrobes] = useState([]);
  const [books, setBooks] = useState([]);
  const [shoes, setShoes] = useState([]);
  const [tvunits, setTvunits] = useState([]);
  const [teatables, setTeatables] = useState([]);
  const [mattresses, setMattresses] = useState([]);
  const [corners, setCorners] = useState([]);

  useEffect(() => {
    db.collection("tables")
      .orderBy("createdAt", "desc")
      .limit(1)
      .get()
      .then((Snapshot) => {
        const newtables = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setTables(newtables);
      })
      .catch();
  }, []);

  useEffect(() => {
    db.collection("beds")
      .orderBy("createdAt", "desc")
      .limit(1)
      .get()
      .then((Snapshot) => {
        const newBeds = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setBeds(newBeds);
      })
      .catch();
  }, []);

  useEffect(() => {
    db.collection("chairs")
      .orderBy("createdAt", "desc")
      .limit(1)
      .get()
      .then((Snapshot) => {
        const newchairs = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setChairs(newchairs);
      })
      .catch();
  }, []);

  useEffect(() => {
    db.collection("storage")
      .orderBy("createdAt", "desc")
      .limit(1)
      .get()
      .then((Snapshot) => {
        const newstorages = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setStorages(newstorages);
      })
      .catch();
  }, []);

  useEffect(() => {
    db.collection("diningtable")
      .orderBy("createdAt", "desc")
      .limit(1)
      .get()
      .then((Snapshot) => {
        const newdinings = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setDinings(newdinings);
      })
      .catch();
  }, []);

  useEffect(() => {
    db.collection("dressing")
      .orderBy("createdAt", "desc")
      .limit(1)
      .get()
      .then((Snapshot) => {
        const newdressings = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setDressings(newdressings);
      })
      .catch();
  }, []);

  useEffect(() => {
    db.collection("sofa")
      .orderBy("createdAt", "desc")
      .limit(1)
      .get()
      .then((Snapshot) => {
        const newsofas = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setSofas(newsofas);
      })
      .catch();
  }, []);

  useEffect(() => {
    db.collection("wardrobe")
      .orderBy("createdAt", "desc")
      .limit(1)
      .get()
      .then((Snapshot) => {
        const newWardrobe = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setWardrobes(newWardrobe);
      })
      .catch();
  }, []);

  useEffect(() => {
    db.collection("bookshelve")
      .orderBy("createdAt", "desc")
      .limit(1)
      .get()
      .then((Snapshot) => {
        const newbookshelve = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setBooks(newbookshelve);
      })
      .catch();
  }, []);

  useEffect(() => {
    db.collection("shoerack")
      .orderBy("createdAt", "desc")
      .limit(1)
      .get()
      .then((Snapshot) => {
        const newshoerack = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setShoes(newshoerack);
      })
      .catch();
  }, []);

  useEffect(() => {
    db.collection("tvunit")
      .orderBy("createdAt", "desc")
      .limit(1)
      .get()
      .then((Snapshot) => {
        const newtvunit = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setTvunits(newtvunit);
      })
      .catch();
  }, []);

  useEffect(() => {
    db.collection("teatable")
      .orderBy("createdAt", "desc")
      .limit(1)
      .get()
      .then((Snapshot) => {
        const newteatable = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setTeatables(newteatable);
      })
      .catch();
  }, []);

  useEffect(() => {
    db.collection("mattresses")
      .orderBy("createdAt", "desc")
      .limit(1)
      .get()
      .then((Snapshot) => {
        const newmattresses = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setMattresses(newmattresses);
      })
      .catch();
  }, []);
  useEffect(() => {
    db.collection("corner")
      .orderBy("createdAt", "desc")
      .limit(1)
      .get()
      .then((Snapshot) => {
        const newcorner = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setCorners(newcorner);
      })
      .catch();
  }, []);

  return (
    <div className="container">
      <h1 className="mb-4 mt-4">Newly Added Products</h1>
      <div className="row center">
        {tables.map((table) => {
          return (
            <div className="col-md-4 mb-4 mt-4" key={table.id}>
              <div className="card">
                <img
                  src={table.urlone}
                  alt="..."
                  className="card-img-top img-fluid"
                />
                <div className="card-body">
                  <h5>{table.name}</h5>
                  <p>&#x20B9; {table.price}</p>
                  <Link
                    to={`/tableDetail/${table.id}`}
                    className="btn btn-info"
                    key={table.id}
                  >
                    order
                  </Link>
                </div>
              </div>
            </div>
          );
        })}

        {chairs.map((chair) => {
          return (
            <div className="col-md-4 mb-4 mt-4" key={chair.id}>
              <div className="card">
                <img
                  src={chair.urlone}
                  alt="..."
                  className="card-img-top img-fluid "
                />
                <div className="card-body">
                  <h5>{chair.name}</h5>
                  <p>&#x20B9; {chair.price}</p>
                  <Link
                    to={`/chairDetail/${chair.id}`}
                    className="btn btn-info"
                    key={chair.id}
                  >
                    order
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
        {beds.map((bed) => {
          return (
            <div className="col-md-4 mb-4 mt-4" key={bed.id}>
              <div className="card">
                <img
                  src={bed.urlone}
                  alt="..."
                  className="card-img-top img-fluid "
                />
                <div className="card-body">
                  <h5>{bed.name}</h5>
                  <p>&#x20B9; {bed.price}</p>
                  <Link
                    to={`/productDetail/${bed.id}`}
                    className="btn btn-info"
                    key={bed.id}
                  >
                    order
                  </Link>
                </div>
              </div>
            </div>
          );
        })}

        {dressings.map((dressing) => {
          return (
            <div className="col-md-4 mb-4 mt-4" key={dressing.id}>
              <div className="card">
                <img
                  src={dressing.urlone}
                  alt="..."
                  className="card-img-top img-fluid "
                />
                <div className="card-body">
                  <h5>{dressing.name}</h5>
                  <p>&#x20B9; {dressing.price}</p>
                  <Link
                    to={`/dressingDetail/${dressing.id}`}
                    className="btn btn-info"
                    key={dressing.id}
                  >
                    order
                  </Link>
                </div>
              </div>
            </div>
          );
        })}

        {wardrobes.map((wardrobe) => {
          return (
            <div className="col-md-4 mb-4 mt-4" key={wardrobe.id}>
              <div className="card">
                <img
                  src={wardrobe.urlone}
                  alt="..."
                  className="card-img-top img-fluid "
                />
                <div className="card-body">
                  <h5>{wardrobe.name}</h5>
                  <p>&#x20B9; {wardrobe.price}</p>
                  <Link
                    to={`/wardrobeDetail/${wardrobe.id}`}
                    className="btn btn-info"
                    key={wardrobe.id}
                  >
                    order
                  </Link>
                </div>
              </div>
            </div>
          );
        })}

        {dinings.map((dining) => {
          return (
            <div className="col-md-4 mb-4 mt-4" key={dining.id}>
              <div className="card">
                <img
                  src={dining.urlone}
                  alt="..."
                  className="card-img-top img-fluid "
                />
                <div className="card-body">
                  <h5>{dining.name}</h5>
                  <p>&#x20B9; {dining.price}</p>
                  <Link
                    to={`/diningDetail/${dining.id}`}
                    className="btn btn-info"
                    key={dining.id}
                  >
                    order
                  </Link>
                </div>
              </div>
            </div>
          );
        })}

        {sofas.map((sofa) => {
          return (
            <div className="col-md-4 mb-4 mt-4" key={sofa.id}>
              <div className="card">
                <img
                  src={sofa.urlone}
                  alt="..."
                  className="card-img-top img-fluid "
                />
                <div className="card-body">
                  <h5>{sofa.name}</h5>
                  <p>&#x20B9; {sofa.price}</p>
                  <Link
                    to={`/sofaDetail/${sofa.id}`}
                    className="btn btn-info"
                    key={sofa.id}
                  >
                    order
                  </Link>
                </div>
              </div>
            </div>
          );
        })}

        {shoes.map((shoe) => {
          return (
            <div className="col-md-4 mb-4 mt-4" key={shoe.id}>
              <div className="card">
                <img
                  src={shoe.urlone}
                  alt="..."
                  className="card-img-top img-fluid "
                />
                <div className="card-body">
                  <h5>{shoe.name}</h5>
                  <p>&#x20B9; {shoe.price}</p>
                  <Link
                    to={`/shoeRackDetail/${shoe.id}`}
                    className="btn btn-info"
                    key={shoe.id}
                  >
                    order
                  </Link>
                </div>
              </div>
            </div>
          );
        })}

        {storages.map((storage) => {
          return (
            <div className="col-md-4 mb-4 mt-4" key={storage.id}>
              <div className="card">
                <img
                  src={storage.urlone}
                  alt="..."
                  className="card-img-top img-fluid "
                />
                <div className="card-body">
                  <h5>{storage.name}</h5>
                  <p>&#x20B9; {storage.price}</p>
                  <Link
                    to={`/storageDetail/${storage.id}`}
                    className="btn btn-info"
                    key={storage.id}
                  >
                    order
                  </Link>
                </div>
              </div>
            </div>
          );
        })}

        {mattresses.map((mattress) => {
          return (
            <div className="col-md-4 mb-4 mt-4" key={mattress.id}>
              <div className="card">
                <img
                  src={mattress.urlone}
                  alt="..."
                  className="card-img-top img-fluid "
                />
                <div className="card-body">
                  <h5>{mattress.name}</h5>
                  <p>&#x20B9; {mattress.price}</p>
                  <Link
                    to={`/mattressDetail/${mattress.id}`}
                    className="btn btn-info"
                    key={mattress.id}
                  >
                    order
                  </Link>
                </div>
              </div>
            </div>
          );
        })}

        {teatables.map((teatable) => {
          return (
            <div className="col-md-4 mb-4 mt-4" key={teatable.id}>
              <div className="card">
                <img
                  src={teatable.urlone}
                  alt="..."
                  className="card-img-top img-fluid "
                />
                <div className="card-body">
                  <h5>{teatable.name}</h5>
                  <p>&#x20B9; {teatable.price}</p>
                  <Link
                    to={`/teatableDetail/${teatable.id}`}
                    className="btn btn-info"
                    key={teatable.id}
                  >
                    order
                  </Link>
                </div>
              </div>
            </div>
          );
        })}

        {books.map((book) => {
          return (
            <div className="col-md-4 mb-4 mt-4" key={book.id}>
              <div className="card">
                <img
                  src={book.urlone}
                  alt="..."
                  className="card-img-top img-fluid "
                />
                <div className="card-body">
                  <h5>{book.name}</h5>
                  <p>&#x20B9; {book.price}</p>
                  <Link
                    to={`/bookDetail/${book.id}`}
                    className="btn btn-info"
                    key={book.id}
                  >
                    order
                  </Link>
                </div>
              </div>
            </div>
          );
        })}

        {corners.map((corner) => {
          return (
            <div className="col-md-4 mb-4 mt-4" key={corner.id}>
              <div className="card">
                <img
                  src={corner.urlone}
                  alt="..."
                  className="card-img-top img-fluid "
                />
                <div className="card-body">
                  <h5>{corner.name}</h5>
                  <p>&#x20B9; {corner.price}</p>
                  <Link
                    to={`/cornorDetail/${corner.id}`}
                    className="btn btn-info"
                    key={corner.id}
                  >
                    order
                  </Link>
                </div>
              </div>
            </div>
          );
        })}

        {tvunits.map((tvunit) => {
          return (
            <div className="col-md-4 mb-4 mt-4" key={tvunit.id}>
              <div className="card">
                <img
                  src={tvunit.urlone}
                  alt="..."
                  className="card-img-top img-fluid "
                />
                <div className="card-body">
                  <h5>{tvunit.name}</h5>
                  <p>&#x20B9; {tvunit.price}</p>
                  <Link
                    to={`/tvunitDetail/${tvunit.id}`}
                    className="btn btn-info"
                    key={tvunit.id}
                  >
                    order
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NewlyAdded;
