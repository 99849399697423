import React from "react";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import SignedOut from "./Auth/SignedOut";
import SignedIn from "./Auth/SignedIn";
import { useContext } from "react";
import { AuthContext } from "./contexts/AuthProvider";

function Navbar() {
  const [authstate, setAuthState] = useContext(AuthContext);
  const links = authstate.user ? <SignedIn /> : <SignedOut />;

  const help = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui px-5">
            <h1 className="text-success">Help!</h1>
            <ul className="help">
              <li className="text-info">
                Do you ship to my city and location?
              </li>
              <li>
                We currently deliver in jhardhand, west bengal and Bihar region.
              </li>
              <li className="text-info">How can I place a bulk order?</li>
              <li>
                If you wish to place an order of large quantities, or if you
                wish to order for your business/home/guest house etc, give us a
                call on +91 9031303351 and +91821065574 or fill this form here.
                We currently are unable to customise products per your
                specifications but we can suggest alternatives that exist in our
                catalogue depending on your request.
              </li>
            </ul>

            <button className="btn btn-success" onClick={onClose}>
              Ok
            </button>
          </div>
        );
      },
    });
  };

  return (
    <div>
      <nav className="navbar navbar-expand-md sticky-top navbar-dark bg-dark">
        <Link className="navbar-brand" to="/">
          BOOK MY FURNITURE
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon "></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item active">
              <Link className="nav-link" to="/cart">
                Cart <i className="fa fa-shopping-basket"></i>{" "}
                <span className="sr-only"></span>
              </Link>
            </li>
            <li className="nav-item active">
              <Link className="nav-link" to="/Bulk">
                Bulk order<span className="sr-only">(current)</span>
              </Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={help}>
                Help <i className="fa fa-exclamation-circle"></i>
              </a>
            </li>
            <li className="nav-item">{links}</li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
