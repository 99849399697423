import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { db } from "../../config/Config";

function PopularBed() {
  const [beds, setBeds] = useState();

  useEffect(() => {
    db.collection("beds")
      .get()
      .then((Snapshot) => {
        const newbeds = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(newbeds);
        setBeds(newbeds);
      })
      .catch();
  }, []);

  return (
    <div className="row">
      {beds &&
        beds.map((bed) => {
          return (
            <div className="col-md-4 mb-4 mt-4" key={bed.id}>
              <div className="card">
                <img
                  src={bed.urlone}
                  alt="..."
                  className="card-img-top img-fluid "
                />
                <div className="card-body">
                  <h5>{bed.name}</h5>
                  <p>&#x20B9; {bed.price}</p>
                  <Link
                    to={`/productDetail/${bed.id}`}
                    className="btn btn-info"
                    key={bed.id}
                  >
                    order
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default PopularBed;
