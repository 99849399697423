import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../config/Config";

function PopularTvUnit() {
  const [tvunits, setTvunits] = useState([]);

  useEffect(() => {
    db.collection("tvunit")
      .get()
      .then((Snapshot) => {
        const newtvunit = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(newtvunit);
        setTvunits(newtvunit);
      })
      .catch();
  }, []);

  return (
    <div className="row">
      {tvunits &&
        tvunits.map((tvunit) => {
          return (
            <div className="col-md-4 mb-4 mt-4" key={tvunit.id}>
              <div className="card">
                <img
                  
                  src={tvunit.urlone}
                  alt="..."
                  className="card-img-top img-fluid "
                />
                <div className="card-body">
                  <h5>{tvunit.name}</h5>
                  <p>&#x20B9; {tvunit.price}</p>
                  <Link
                    to={`/tvunitDetail/${tvunit.id}`}
                    className="btn btn-info"
                    key={tvunit.id}
                  >
                    order
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default PopularTvUnit;
