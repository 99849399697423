import React from "react";
import { Link } from "react-router-dom";

function AdminNavbar() {
  return (
    <div className="container-fluid no-printme">
      <ul className="nav nav-pills mt-4 mb-4">
        <li className="nav-item">
          <Link to="/admin/" className="nav-link">
            Add Table
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/addbed" className="nav-link">
            Add Bed
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/addchair" className="nav-link">
            Add Chair
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/adddressing" className="nav-link">
            Add Dressing
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/addwardrobe" className="nav-link">
            Add Wardrobe
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/addstorage" className="nav-link">
            Add Storage
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/addmatt" className="nav-link">
            Add Mattresses
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/addcoffee" className="nav-link">
            Add Coffee/tea table
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/addbook" className="nav-link">
            Add Book
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/adddining" className="nav-link">
            Add Dining
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/addsofa" className="nav-link">
            Add Sofa
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/addshoe" className="nav-link">
            Add ShoeRack
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/addtv" className="nav-link">
            Add Tv Unit
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/addcorner" className="nav-link">
            Add Corner
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/Booking" className="nav-link">
            Booking
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default AdminNavbar;
