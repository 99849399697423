import React from "react";
import { Link } from "react-router-dom";

function PopularItemList() {
  return (
    <div className="container">
      <h1 className="mt-4 mb-4">POPULAR PRODUCTS</h1>
      <div className="row">
        <div className="col-md-3 mt-4">
          <div className="card">
            <div className="card-image">
              <img
                className="card-img-top"
                src="https://www.mydomaine.com/thmb/gppWyEJmLfkEkZdhlAPpjYAc17w=/600x400/filters:max_bytes(150000):strip_icc()/cdn.cliqueinc.com__cache__posts__222765__-222765-1493392609702-image.700x0c-e3450bbbad1f469d8078f5776751ffac.jpg"
                alt="..."
              />
            </div>
            <div className="card-body">
              <h5>Bed Room</h5>
              <p></p>
              <Link to="/bedroom" className="btn btn-info">
                Check Now
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-3 mt-4">
          <div className="card">
            <div className="card-image">
              <img
                className="card-img-top"
                src="https://www.ldg.co.uk/wp-content/uploads/2018/04/Fresh-Design-Blog-Living-Room-Decor-Ideas-600x400.jpg"
                alt="..."
              />
            </div>
            <div className="card-body">
              <h5>Living Room</h5>
              <p></p>
              <Link to="/livingroom" className="btn btn-info">
                Check Now
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-3 mt-4">
          <div className="card">
            <div className="card-image">
              <img
                className="card-img-top"
                src="https://homedesignlover.com/wp-content/uploads/2014/09/17-traditional-desk-work-room.jpg"
                alt="..."
              />
            </div>
            <div className="card-body">
              <h5>Study Room/Office</h5>
              <p></p>
              <Link to="/office" className="btn btn-info">
                Check Now
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-3 mt-4">
          <div className="card">
            <div className="card-image">
              <img
                className="card-img-top"
                src="https://static01.nyt.com/images/2017/09/11/smarter-living/dining-tables-slide-36XL/dining-tables-slide-36XL-articleLarge.jpg?quality=90&auto=webp"
                alt="..."
              />
            </div>
            <div className="card-body">
              <h5>Dining Room</h5>
              <p></p>
              <Link to="/dining" className="btn btn-info">
                Check Now
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-3 mt-4">
          <div className="card">
            <div className="card-image">
              <img
                className="card-img-top"
                src="https://unitedfurnitureco.com/image/cache/catalog/BED/Fortworth/fortworth_6_door_wardrobe-600x400.jpg"
                alt="..."
              />
            </div>
            <div className="card-body">
              <h5>Wadrobes</h5>
              <p></p>
              <Link to="/wardrobe" className="btn btn-info">
                Check Now
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-3 mt-4">
          <div className="card">
            <div className="card-image">
              <img
                className="card-img-top"
                src="https://www.knoll.com/nkdc/images/inline/2c/work-chairs-landing-page-btn.jpg"
                alt="..."
              />
            </div>
            <div className="card-body">
              <h5>Chairs</h5>
              <p></p>
              <Link to="/chair" className="btn btn-info">
                Check Now
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-3 mt-4">
          <div className="card">
            <div className="card-image">
              <img
                className="card-img-top"
                src="https://wpbeaches.com/wp-content/uploads/2019/05/woocommerce-extra-product-tabs.jpg"
                alt="..."
              />
            </div>
            <div className="card-body">
              <h5>Extra</h5>
              <p></p>
              <Link to="" className="btn btn-info">
                Check Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopularItemList;
