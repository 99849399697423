import React, { useState } from "react";
import { useEffect } from "react";
import { db } from "../../config/Config";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import emailjs from "emailjs-com";
import { Redirect } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../contexts/AuthProvider";

const SofaDetail = (props) => {
  const [details, setDetails] = useState([]);
  const [username, setUserName] = useState([]);
  const [phone, setPhone] = useState([]);
  const [email, setMail] = useState([]);
  const [address, setAddress] = useState([]);
  const [authstate, setAuthState] = useContext(AuthContext);

  useEffect(() => {
    db.collection("sofa")
      .doc(props.match.params.id)
      .get()
      .then(function (doc) {
        const newbeds = doc.data();
        console.log(newbeds);
        setDetails(newbeds);
      });
  }, [props.match.params.id]);

  const addUserName = (e) => {
    setUserName(e.target.value);
  };
  const addPhone = (e) => {
    setPhone(e.target.value);
  };
  const addMail = (e) => {
    setMail(e.target.value);
  };
  const addAddress = (e) => {
    setAddress(e.target.value);
  };
  const templateParams = {
    username,
    phone,
  };
  const submitHandler = (e) => {
    e.preventDefault();
    db.collection("bookings")
      .add({
        username,
        phone,
        address,
        email,
        color: details.color,
        itemname: details.name,
        model: details.model,
        price: details.price,
        createdAt: new Date(),
      })
      .then(() => {
        emailjs
          .send(
            "service_7imv8b8",
            "template_ln5duyg",
            templateParams,
            "user_i09OH1iltYunUUsqN1V8R"
          )
          .then(
            (result) => {
              console.log(result.text);
            },
            (error) => {
              console.log(error.text);
            }
          );
        setUserName("");
        setPhone("");
        setMail("");
        setAddress("");
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <h1 className="text-success">Booking Successful!</h1>
                <p>Contact us for any query</p>
                <button className="btn btn-success" onClick={onClose}>
                  Ok
                </button>
              </div>
            );
          },
        });
      });
  };

  const newbasket = () => {
    db.collection("cart")
      .add({
        email: authstate.user.email,
        username: authstate.user.displayName,
        phone: authstate.user.photoURL,
        color: details.color,
        itemname: details.name,
        model: details.model,
        price: details.price,
        createdAt: new Date(),
      })
      .then(() => {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="custom-ui">
                <h1 className="text-success">Added to Cart</h1>

                <button className="btn btn-success" onClick={onClose}>
                  Ok
                </button>
              </div>
            );
          },
        });
      });
  };

  if (!authstate.user) {
    return <Redirect to="/Login" />;
  }
  return (
    <div>
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-ride="carousel"
      >
        <ol className="carousel-indicators">
          <li
            data-target="#carouselExampleIndicators"
            data-slide-to="0"
            className="active"
          ></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
          <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        </ol>
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={details.urlone} className="d-block img-fluid" alt="..." />
          </div>
          <div className="carousel-item">
            <img src={details.urltwo} className="d-block img-fluid" alt="..." />
          </div>
          <div className="carousel-item">
            <img
              src={details.urlthree}
              className="d-block img-fluid"
              alt="..."
            />
          </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
      <div className="container">
        <form onSubmit={submitHandler}>
          <div className="row">
            <div className="col-md-6">
              <ul className="list-group mb-4 mt-2">
                <li className="list-group-item list-group-item-info">
                  Name: {details.name}
                </li>
                <li className="list-group-item">&#x20B9; {details.price}</li>
                <li className="list-group-item">Color: {details.color}</li>

                <li className="list-group-item">
                  Leg Material: {details.legmaterial}
                </li>
                <li className="list-group-item">
                  Sub Material: {details.submaterial}
                </li>
                <li className="list-group-item">
                  Upholstery Material: {details.upholsterymaterial}
                </li>
                <li className="list-group-item">model: {details.model}</li>
                <li className="list-group-item">Size: {details.size}</li>
                <li className="list-group-item">Seater: {details.seater}</li>
                <li className="list-group-item">Feature: {details.feature}</li>
                <li className="list-group-item">Caution: {details.caution}</li>
              </ul>
            </div>
            <div className="col-md-6">
              <input
                required
                type="text"
                placeholder="Name:"
                className="form-control mb-2 mt-2"
                value={username}
                onChange={addUserName}
              />
              <input
                required
                type="text"
                placeholder="Address:"
                className="form-control mb-2 mt-2"
                value={address}
                onChange={addAddress}
              />
              <input
                required
                type="number"
                placeholder="Phone No.:"
                className="form-control mb-2 mt-2"
                value={phone}
                onChange={addPhone}
              />
              <input
                required
                type="email"
                placeholder="E-mail:"
                className="form-control mb-2 mt-2"
                value={email}
                onChange={addMail}
              />
              <button type="submit" className="btn btn-success mt-4 mb-4">
                Buy Now
              </button>
            </div>
          </div>
        </form>
        <button onClick={newbasket} className="btn btn-success mt-4 mb-4">
          Add to Cart
        </button>
      </div>
    </div>
  );
};

export default SofaDetail;
