import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../config/Config";

function PopularTable() {
  const [tables, setTables] = useState();

  useEffect(() => {
    db.collection("tables")
      .get()
      .then((Snapshot) => {
        const newtables = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(newtables);
        setTables(newtables);
      })
      .catch();
  }, []);

  return (
    <div className="row">
      {tables &&
        tables.map((table) => {
          return (
            <div className="col-md-4 mb-4 mt-4" key={table.id}>
              <div className="card">
                <img
                  src={table.urlone}
                  alt="..."
                  className="card-img-top img-fluid "
                />
                <div className="card-body">
                  <h5>{table.name}</h5>
                  <p>&#x20B9; {table.price}</p>
                  <Link
                    to={`/tableDetail/${table.id}`}
                    className="btn btn-info"
                    key={table.id}
                  >
                    order
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default PopularTable;
