import React from "react";
import PopularDining from "../popularItem/PopularDining";

function DiningRoom() {
  return (
    <div>
      <div className="dining">
        <div className="box-heading-container">
          <h1 className="center text-white">Dining Room</h1>
          <p className="text-white">
            Dining tables come in all shapes, sizes, and finishes.
          </p>
        </div>
      </div>
      <div className="container">
        <h1 className="display-4">Dining </h1>
        <PopularDining />
      </div>
    </div>
  );
}

export default DiningRoom;
