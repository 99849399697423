import React from "react";
import PopularBed from "../popularItem/PopularBed";
import PopularCorners from "../popularItem/PopularCorners";
import PopularDressing from "../popularItem/PopularDressing";
import PopularMattresses from "../popularItem/PopularMattresses";
import PopularStorage from "../popularItem/PopularStorage";

function BedRoom() {
  return (
    <div>
      <div className="bedroom">
        <div className="box-heading-container">
          <h1 className="center text-white">BED ROOM</h1>
          <p className="text-white">
            A bed is more than just a place to sleep.
          </p>
        </div>
      </div>
      <div className="container">
        <h1 className="display-4">BED</h1>
        <PopularBed />
        <h1 className="display-4">DRESSING</h1>
        <PopularDressing />
        <h1 className="display-4">STORAGES</h1>
        <PopularStorage />
        <h1 className="display-4"> CORNER</h1>
        <PopularCorners />
        <h1 className="display-4">MATTRESSES</h1>
        <PopularMattresses />
      </div>
    </div>
  );
}

export default BedRoom;
