import React from "react";
import { Link } from "react-router-dom";

function SignedOut() {
  return (
    <Link className="nav-link" to="/Login">
      Sign In
    </Link>
  );
}

export default SignedOut;
