import React from "react";
import PopularWardrobe from "../popularItem/PopularWardrobe";

function Wardrobes() {
  return (
    <div>
      <div className="wardrobe">
        <div className="box-heading-container">
          <h1 className="center text-white">Wardroobe</h1>
          <p className="text-white">
            Get all kind of wardrobe with different varities of shape, size and
            colour in best price.
          </p>
        </div>
      </div>
      <div className="container">
        <h1 className="display-4">WARDROBES</h1>
        <PopularWardrobe />
      </div>
    </div>
  );
}

export default Wardrobes;
