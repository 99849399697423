import React from "react";
import PopularShoerack from "../popularItem/PopularShoerack";
import PopularSofa from "../popularItem/PopularSofa";
import PopularTeaTable from "../popularItem/PopularTeaTable";
import PopularTvUnit from "../popularItem/PopularTvUnit";

function LivingRoom() {
  return (
    <div>
      <div className="living-room">
        <div className="box-heading-container">
          <h1 className="center text-white">Living Room</h1>
          <p className="text-white">
            “Be faithful to your own taste, because nothing you really like is
            ever out of style.”
          </p>
        </div>
      </div>
      <div className="container">
        <h1 className="display-4">SOFA</h1>
        <PopularSofa />
        <h1 className="display-4">SHOE-RACK</h1>
        <PopularShoerack />
        <h1 className="display-4">COFFEE & TEA TABLES</h1>
        <PopularTeaTable />
        <h1 className="display-4">TV UNIT</h1>
        <PopularTvUnit />
      </div>
    </div>
  );
}

export default LivingRoom;
