import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../config/Config";

function PopularWardrobe() {
  const [wardrobes, setWardrobs] = useState();

  useEffect(() => {
    db.collection("wardrobe")
      .get()
      .then((Snapshot) => {
        const newswardrobes = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(newswardrobes);
        setWardrobs(newswardrobes);
      })
      .catch();
  }, []);

  return (
    <div className="row">
      {wardrobes &&
        wardrobes.map((wardrobe) => {
          return (
            <div className="col-md-4 mb-4 mt-4" key={wardrobe.id}>
              <div className="card">
                <img
                  src={wardrobe.urlone}
                  alt="..."
                  className="card-img-top img-fluid "
                />
                <div className="card-body">
                  <h5>{wardrobe.name}</h5>
                  <p>&#x20B9; {wardrobe.price}</p>
                  <Link
                    to={`/wardrobeDetail/${wardrobe.id}`}
                    className="btn btn-info"
                    key={wardrobe.id}
                  >
                    order
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default PopularWardrobe;
