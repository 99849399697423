import React, { useEffect } from "react";
import { useState } from "react";
import { db } from "../../config/Config";

const BookingDetail = (props) => {
  const [details, setDetails] = useState([]);
  useEffect(() => {
    db.collection("bookings")
      .doc(props.match.params.id)
      .get()
      .then(function (doc) {
        const newbeds = doc.data();
        console.log(newbeds);
        setDetails(newbeds);
      });
  }, [props.match.params.id]);

  return (
    <div>
      <div className="card printme">
        <div className="card-body">
          <h5 className="card-title">Invoice</h5>
          <hr />
          <div className="row no-gutter">
            <div className="col-md-6">
              <ul className="bill">
                <li className="text-left">
                  <strong>Shipping Address</strong>
                </li>
                <li className="text-left">
                  Invoice-I'd: <strong>{props.match.params.id}</strong>
                </li>
                <li className="text-left">
                  Name: <strong>{details.username}</strong>
                </li>
                <li className="text-left">phone_no.: +91 {details.phone}</li>
                <li className="text-left">E-mail: {details.email}</li>
                <li className="text-left">Address: {details.address}</li>
              </ul>
            </div>
            <div className="col-md-6">
              <ul className="bill">
                <li className="text-left">
                  <strong> product Details</strong>
                </li>
                <li className="text-left">Date: {Date().toString()}</li>
                <li className="text-left">product: {details.itemname}</li>
                <li className="text-left">model: {details.model}</li>
                <li className="text-left">color: {details.color}</li>
                <li className="text-left">price: &#x20B9;{details.price}</li>
              </ul>
            </div>
            <div className="col-12">
              <button
                className="btn btn-success center"
                onClick={() => window.print()}
              >
                Print
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetail;
