import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { auth } from "../../config/Config";
import { AuthContext } from "../contexts/AuthProvider";

function SignedIn() {
  const [authstate, setAuthState] = useContext(AuthContext);
  const history = useHistory();
  async function signout() {
    auth.signOut().then((res) => {
      setAuthState({
        user: null,
      });
      history.push("/");
    });
  }
  return (
    <a className="nav-link" onClick={signout}>
      Logout <span className="text-warning">{authstate.user.displayName}</span>
    </a>
  );
}

export default SignedIn;
