import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../config/Config";

function PopularStorage() {
  const [storages, setStorages] = useState([]);

  useEffect(() => {
    db.collection("storage")
      .get()
      .then((Snapshot) => {
        const newstorages = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setStorages(newstorages);
      })
      .catch();
  }, []);

  return (
    <div className="row">
      {storages &&
        storages.map((storage) => {
          return (
            <div className="col-md-4 mt-4 mb-4" key={storage.id}>
              <div className="card">
                <img
                  src={storage.urlone}
                  alt="..."
                  className="card-img-top img-fluid "
                />
                <div className="card-body">
                  <h5>{storage.name}</h5>
                  <p>&#x20B9; {storage.price}</p>
                  <Link
                    to={`/storageDetail/${storage.id}`}
                    className="btn btn-info"
                    key={storage.id}
                  >
                    order
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default PopularStorage;
