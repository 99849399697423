import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { db } from "../../config/Config";

function PopularChair() {
  const [chairs, setChairs] = useState();

  useEffect(() => {
    db.collection("chairs")
      .get()
      .then((Snapshot) => {
        const newchairs = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(newchairs);
        setChairs(newchairs);
      })
      .catch();
  }, []);

  return (
    <div className="row">
      {chairs &&
        chairs.map((chair) => {
          return (
            <div className="col-md-4 mb-4 mt-4" key={chair.id}>
              <div className="card">
                <img
                  src={chair.urlone}
                  alt="..."
                  className="card-img-top img-fluid "
                />
                <div className="card-body">
                  <h5>{chair.name}</h5>
                  <p>&#x20B9; {chair.price}</p>
                  <Link
                    to={`/chairDetail/${chair.id}`}
                    className="btn btn-info"
                    key={chair.id}
                  >
                    order
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default PopularChair;
