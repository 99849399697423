import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { db } from "../../config/Config";

function Booking() {
  const [bookings, setBookings] = useState();

  useEffect(() => {
    db.collection("bookings")
      .orderBy("createdAt", "desc")
      .get()
      .then((Snapshot) => {
        const newbeds = Snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(newbeds);
        setBookings(newbeds);
      })
      .catch();
  }, []);

  return (
    <div>
      <div className="jumbotron jumbotron-fluid">
        <div className="container">
          <h1 className="display-4">Your Bookings</h1>
          <p className="lead">
            <span className="text-danger">WARNING!</span> Don't share this page
            link to anyone.
          </p>
        </div>
      </div>

      {bookings &&
        bookings.map((booking) => {
          return (
            <div className="col-12 mb-4 mt-4" key={booking.id}>
              <ul className="list-group">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  {booking.username} - I'D: {booking.id}
                  <Link
                    to={`/admin/bookingDetail/${booking.id}`}
                    className="badge badge-primary badge-pill"
                    key={booking.id}
                  >
                    See Details
                  </Link>
                </li>
              </ul>
            </div>
          );
        })}
    </div>
  );
}

export default Booking;
